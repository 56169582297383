










































import {
  Component, Vue, Prop,
} from 'vue-property-decorator';

import BoxContainer from '@/components/BoxContainer/BoxContainer.vue';

import HomeOutline from '@/assets/icons/ranking/HomeOutline.vue';
import Question from '@/assets/icons/ranking/Question.vue';
import QuestionFull from '@/assets/icons/ranking/QuestionFull.vue';
import LikedDoubt from '@/assets/icons/ranking/LikedDoubt.vue';
import DislikedDoubt from '@/assets/icons/ranking/DislikedDoubt.vue';
import ExpIcon from '@/assets/icons/Exp.vue';

import { LIST_INSTRUCTIONS_GAIN, LIST_INSTRUCTIONS_LOSS } from '../../constants/ListInstructions';

const TITLE_GAIN = 'Como você  pode ganhar EXP';
const TITLE_LOSS = 'Cuidado para não perder EXP';

@Component({
  components: {
    BoxContainer,
    HomeOutline,
    Question,
    QuestionFull,
    LikedDoubt,
    DislikedDoubt,
    ExpIcon,
  },
})
export default class Instructions extends Vue {
  @Prop({ default: true }) isGain!: boolean;

  get title() {
    return this.isGain ? TITLE_GAIN : TITLE_LOSS;
  }

  get instructions() {
    return this.isGain ? LIST_INSTRUCTIONS_GAIN : LIST_INSTRUCTIONS_LOSS;
  }
}
